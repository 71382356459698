const CONSENT_STATE_VARIANTS = {
  new: 'primary',
  investigation: 'danger',
  'investigation - contacted': 'danger',
  'investigation - declined': 'danger',
  'approved by support': 'warning',
  completed: 'success',
}

export {
  CONSENT_STATE_VARIANTS,
}
