<template>
  <b-row>
    <b-col
      lg="6"
      sm="12"
    >
      <entity-form
        :id="consent ? consent.id : null"
        ref="consentForm"
        module-name="contractConsentsList"
        :model="form"
        @submit="onSubmit"
      >
        <b-form-group
          label="Full name"
        >
          <b-form-input
            v-model="form.full_name"
            placeholder="Full name"
          />
        </b-form-group>

        <b-form-group
          label="Address"
        >
          <b-form-textarea
            v-model="form.address"
            placeholder="Address"
          />
        </b-form-group>

        <b-form-group
          label="Document type"
        >
          <b-form-input
            v-model="form.doc_type"
            placeholder="Document type"
          />
        </b-form-group>

        <b-form-group
          label="Document number"
        >
          <b-form-input
            v-model="form.doc_no"
            placeholder="Document number"
          />
        </b-form-group>
        <b-form-group
          label="Document expiration (UTC)"
        >
          <b-form-row>
            <b-col cols="10">
              <b-form-datepicker
                id="example-datepicker"
                v-model="form.doc_expiration"
                class="mb-1"
              />
            </b-col>
            <b-col cols="2">
              <b-button
                variant="outline-primary"
                class="btn-icon mb-1"
                @click="form.doc_expiration = null"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
          </b-form-row>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            v-model="form.photo_required"
          >
            Photo required
          </b-form-checkbox>
        </b-form-group>

        <b-form-group v-if="consent.files || consent.approval_required">
          <b-form-checkbox
            :checked="approvedBySupport"
            @input="form.supportApprovedAt !== null ? form.supportApprovedAt = null : form.supportApprovedAt = new Date().toISOString()"
          >
            Approve consent
          </b-form-checkbox>
        </b-form-group>

        <template v-slot:right-button>
          <div class="d-flex flex-nowrap align-items-center">
            <b-form-select
              v-model="form.reason"
              :options="declineTypes"
              class="m-1"
            />
            <b-button
              class="m-1"
              variant="outline-danger"
              :disabled="form.reason === null"
              @click.prevent="onDecline"
            >
              Decline & send email
            </b-button>
          </div>
        </template>
      </entity-form>
    </b-col>
    <b-col>
      <b-row>
        <h3 class="mt-1">
          Verification images
        </h3>
        <veriff-images
          :verifications="consent.account.user.verifications"
          :img-height="250"
        />
      </b-row>
      <hr>
      <b-row v-if="consent.files">
        <b-col cols="12">
          <h3 class="mt-1">
            Uploaded images
          </h3>
        </b-col>
        <b-col>
          <uploaded-document-images-galery
            :consent-id="consent.id"
            :images="consent.files"
            :img-height="250"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormDatepicker, BRow, BCol, BButton, BFormRow, BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EntityForm from '@/components/ui/forms/EntityForm.vue'
import VeriffImages from '@/components/page/contract-veriff/components/VeriffImages.vue'
import UploadedDocumentImagesGalery from '@/components/forms/contract-consent/components/UploadedDocumentImagesGalery.vue'
import ConsentAPI from '@/api/contract-consent'

export default {
  components: {
    UploadedDocumentImagesGalery,
    VeriffImages,
    EntityForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormDatepicker,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BFormRow,
    BFormSelect,
  },
  props: {
    consent: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        full_name: this.consent ? this.consent.full_name : null,
        address: this.consent ? this.consent.address : null,
        doc_no: this.consent ? this.consent.doc_no : null,
        doc_type: this.consent ? this.consent.doc_type : null,
        doc_expiration: this.consent ? this.consent.doc_expiration : null,
        supportApprovedAt: this.consent ? this.consent.supportApprovedAt : null,
        reason: null,
        photo_required: this.consent ? this.consent.photo_required : false,
      },
      declineTypes: [{ value: null, text: 'Please select decline reason.' }, 'incorrect document number', 'incorrect address', 'incorrect document expiration date', 'expired document', 'invalid files', 'incorrect name'],
    }
  },
  computed: {
    approvedBySupport() {
      return !!this.form.supportApprovedAt
    },
  },
  methods: {
    onSubmit(data) {
      this.$emit('submit', data)
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    onDecline() {
      ConsentAPI.postSaveAndRestoreDecision(this.consent.id, this.form)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Decision was restored successfully',
              icon: 'WarningIcon',
              variant: 'success',
            },
          })
          this.$store.dispatch('contractConsentsList/reloadAll')
          this.onSubmit(response)
        })
    },
  },
}
</script>

<style scoped>

</style>
