<template>
  <div>
    <b-row class="mb-2">
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Full name: </strong>{{ row.item.full_name }}
      </b-col>

      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Address: </strong>{{ row.item.address }}
      </b-col>

      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Document type: </strong>{{ row.item.doc_type }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Document number: </strong>{{ row.item.doc_no }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Document expiration: </strong>{{ row.item.doc_expiration }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Ip: </strong>{{ row.item.ip }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Created at: </strong>{{ formatDateTime(row.item.createdAt) }}
      </b-col>
    </b-row>

    <b-button
      size="sm"
      variant="outline-secondary"
      @click="row.toggleDetails"
    >
      Hide Details
    </b-button>
  </div>
</template>

<script>
import { BCol, BRow, BButton } from 'bootstrap-vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  props: {
    row: Object,
  },
  methods: {
    formatDateTime,
    formatCurrency,
  },
}
</script>
